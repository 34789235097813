import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_ioBroker_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Using ioBroker with your WQHD Camera",
  "path": "/Frequently_Asked_Question/WQHD_with_ioBroker/",
  "dateChanged": "2023-01-20",
  "author": "Mike Polinowski",
  "excerpt": "How can I add my WQHD 2k+ cameras MJPEG Stream or snapshot to my ioBroker Vis dashboard?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Using ioBroker with your WQHD Camera' dateChanged='2023-01-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='How can I add my WQHD 2k+ cameras MJPEG Stream or snapshot to my ioBroker Vis dashboard?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WQHD_with_ioBroker/' locationFR='/fr/Frequently_Asked_Question/WQHD_with_ioBroker/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "using-iobroker-with-your-wqhd-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-iobroker-with-your-wqhd-camera",
        "aria-label": "using iobroker with your wqhd camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using ioBroker with your WQHD Camera`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: How can I add my WQHD 2k+ cameras MJPEG Stream or snapshot to my ioBroker Vis dashboard?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: The easiest way to add your camera's live video is to use the `}<strong parentName="p">{`Simple iFrame Widget`}</strong>{`. All you need to do is to either add the `}<a parentName="p" {...{
        "href": "/en/1440p_Series_CGI_List/#livestream"
      }}>{`JPG or MJPEG Url`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4774cb97ebbda998de7d38b5b6ca85c4/1e2b7/Using_ioBroker_with_your_WQHD_Camera_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABYElEQVQY003NTUvCYADA8X0EQ3CNSUNdCmJYVGJOOoR+gL5LZgcz7Zgfx0vaJQ8eshfDlenm5kvTvdg2a3v2bEkaFEQ//vc/8vD41GZ4XpBEdTZWNEFRRXUm64Zq2Kppv31YTH/EDF6ZgdDhh93+aCgqfUEajOXJVEMm4zEEZqNxe5TJHGcyJ9nsWT5/XiwWC4XTXO6iVGJZVhRFWZYAANYP24YQQgAA8iK+30lOuVxecbk8KIpiGIphntVVDMddbvcGSbK1msGyBstqz22l1ZrS9LRFT2labjaRjmTcy59X1eoaQWyTZCIQ2AwGo6FQlCR9BLEfDgscBx3na7HQDQMA8Lu1HQfqOnIz1Ct9WK1celB03efbiUS2wmESx4NeL45hMb9/wvOmZc3nc9O2F8t/IEQ00wHL5XWtFovFqGTyIJVKpdN7FJWgqN14/DCd5ut1wPNmr6d1u4Dj/jI6nW94yfL7WLBKgQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4774cb97ebbda998de7d38b5b6ca85c4/e4706/Using_ioBroker_with_your_WQHD_Camera_01.avif 230w", "/en/static/4774cb97ebbda998de7d38b5b6ca85c4/d1af7/Using_ioBroker_with_your_WQHD_Camera_01.avif 460w", "/en/static/4774cb97ebbda998de7d38b5b6ca85c4/7f308/Using_ioBroker_with_your_WQHD_Camera_01.avif 920w", "/en/static/4774cb97ebbda998de7d38b5b6ca85c4/e1c99/Using_ioBroker_with_your_WQHD_Camera_01.avif 1380w", "/en/static/4774cb97ebbda998de7d38b5b6ca85c4/aab82/Using_ioBroker_with_your_WQHD_Camera_01.avif 1483w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4774cb97ebbda998de7d38b5b6ca85c4/a0b58/Using_ioBroker_with_your_WQHD_Camera_01.webp 230w", "/en/static/4774cb97ebbda998de7d38b5b6ca85c4/bc10c/Using_ioBroker_with_your_WQHD_Camera_01.webp 460w", "/en/static/4774cb97ebbda998de7d38b5b6ca85c4/966d8/Using_ioBroker_with_your_WQHD_Camera_01.webp 920w", "/en/static/4774cb97ebbda998de7d38b5b6ca85c4/445df/Using_ioBroker_with_your_WQHD_Camera_01.webp 1380w", "/en/static/4774cb97ebbda998de7d38b5b6ca85c4/026e6/Using_ioBroker_with_your_WQHD_Camera_01.webp 1483w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4774cb97ebbda998de7d38b5b6ca85c4/81c8e/Using_ioBroker_with_your_WQHD_Camera_01.png 230w", "/en/static/4774cb97ebbda998de7d38b5b6ca85c4/08a84/Using_ioBroker_with_your_WQHD_Camera_01.png 460w", "/en/static/4774cb97ebbda998de7d38b5b6ca85c4/c0255/Using_ioBroker_with_your_WQHD_Camera_01.png 920w", "/en/static/4774cb97ebbda998de7d38b5b6ca85c4/b1001/Using_ioBroker_with_your_WQHD_Camera_01.png 1380w", "/en/static/4774cb97ebbda998de7d38b5b6ca85c4/1e2b7/Using_ioBroker_with_your_WQHD_Camera_01.png 1483w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4774cb97ebbda998de7d38b5b6ca85c4/c0255/Using_ioBroker_with_your_WQHD_Camera_01.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This works with both your camera's local IP address over `}<strong parentName="p">{`HTTP`}</strong>{` as well as the DDNS address over `}<strong parentName="p">{`HTTPS`}</strong>{` of a remote camera (requires a `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`Port-forwarding rule`}</a>{`).`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Update`}</strong>{`: Using the Snapshot URL as shown below does no longer work. The video stream now stops after receiving the first frame - which is probably related to having to remove the `}<em parentName="p">{`random ID`}</em>{` ioBroker usually adds to the Snapshot path. Using the MJPEG URL instead of the Snapshot URL solves this issue. Also, check out the `}<strong parentName="p">{`jQuery-UI Widget`}</strong>{` as an alternative below.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e7c955521d5045cdc83b50c6865c0a48/66632/Using_ioBroker_with_your_WQHD_Camera_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.347826086956516%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACWklEQVQoz23QzU/acBjA8V6NCaaIhjF5+VmwLpQAmRCZfwte5jyxZCd1uzh0LPHifW6LO2xzzvuOm4sxZnHRIC+Wl6IVCvbXX6FQGqwVfosv22nfw3N6PnmShzg4TKfY4mkV1mSlAuUqUiqwXkXKeUMVG+0KrKfzXLZwkslxR2whky/lT84O0yzL8b8OUoRQKaut5o/tn2/W1t6+e7++/mFj48vm5tbm162Pnz7v7O4KIhRE8RyhpqapnU7HMDqG0cO4cMoTSR4JavfF84W+vr57N9lsNqvV6rDb+/v7H0ejjRwLMxmlkK+zOfn4WGZZlM02ixz7fZvYKTWOFZxYjg9aLB6PBwBA3eSmKPPw8NPpab1cbisKvrhA56ICJVWSVBG2Wi11f5/4loUpZCSWXpKkmaZphmFomnY6naMAkENDsWj0AiGkaV2MJQnidhtjjLvd68lxRLqmSgZ+nXhls9kYhgmFQoFAwO12Pxgfv+9wPJuZ6eRyGs9fCkIjl9NLJV0Q9LOzSwhbe3vE1dUVxnh1ddXr9YbC4VA4/CgSiUxOTk1NMT7fwvx8uVhkk8kaz0NBkARBqlRguayIYnL/N2EY13hxcdFkMgGXy+Vw2B0Ou9MJKMo0MPBkdrZaqxU4DiKE6nUky0iWJYSarVbyKHV3eXlpadBimaCoiNPppygfAH4ArGZzLBbDGGuaZhjG5d90XccY8zx/h+PxOEmS9NhY0O/30rRrZMTjcplJ8hbf7vyr1+thjAWheodXVlYAAMFgMBwOP5yYYHw+fyAARkfn5uZuvtv9L/4DSWatGN3ES7oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e7c955521d5045cdc83b50c6865c0a48/e4706/Using_ioBroker_with_your_WQHD_Camera_02.avif 230w", "/en/static/e7c955521d5045cdc83b50c6865c0a48/d1af7/Using_ioBroker_with_your_WQHD_Camera_02.avif 460w", "/en/static/e7c955521d5045cdc83b50c6865c0a48/7f308/Using_ioBroker_with_your_WQHD_Camera_02.avif 920w", "/en/static/e7c955521d5045cdc83b50c6865c0a48/e1c99/Using_ioBroker_with_your_WQHD_Camera_02.avif 1380w", "/en/static/e7c955521d5045cdc83b50c6865c0a48/b5df0/Using_ioBroker_with_your_WQHD_Camera_02.avif 1504w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e7c955521d5045cdc83b50c6865c0a48/a0b58/Using_ioBroker_with_your_WQHD_Camera_02.webp 230w", "/en/static/e7c955521d5045cdc83b50c6865c0a48/bc10c/Using_ioBroker_with_your_WQHD_Camera_02.webp 460w", "/en/static/e7c955521d5045cdc83b50c6865c0a48/966d8/Using_ioBroker_with_your_WQHD_Camera_02.webp 920w", "/en/static/e7c955521d5045cdc83b50c6865c0a48/445df/Using_ioBroker_with_your_WQHD_Camera_02.webp 1380w", "/en/static/e7c955521d5045cdc83b50c6865c0a48/ca108/Using_ioBroker_with_your_WQHD_Camera_02.webp 1504w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e7c955521d5045cdc83b50c6865c0a48/81c8e/Using_ioBroker_with_your_WQHD_Camera_02.png 230w", "/en/static/e7c955521d5045cdc83b50c6865c0a48/08a84/Using_ioBroker_with_your_WQHD_Camera_02.png 460w", "/en/static/e7c955521d5045cdc83b50c6865c0a48/c0255/Using_ioBroker_with_your_WQHD_Camera_02.png 920w", "/en/static/e7c955521d5045cdc83b50c6865c0a48/b1001/Using_ioBroker_with_your_WQHD_Camera_02.png 1380w", "/en/static/e7c955521d5045cdc83b50c6865c0a48/66632/Using_ioBroker_with_your_WQHD_Camera_02.png 1504w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e7c955521d5045cdc83b50c6865c0a48/c0255/Using_ioBroker_with_your_WQHD_Camera_02.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Note`}</strong>{` that you will have to make sure that ioBroker does not change the URL by appending a random ID (see screenshot above).`}</p>
    <p>{`Alternatively, you can install the `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Live_Video_and_ioBroker_VIS/"
      }}>{`jQuery-UI-Style Widget`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c15d401fe7b5b4449c7319c26159d994/bb3ba/Using_ioBroker_with_your_WQHD_Camera_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABjElEQVQoz02P627bMAyF/Ybr++RtutdY/hTFsA1JmiDOnJsd3yVZliVLcm3RUjw4WLd+IHhIAocEvZ+bvR9c/Uv8O0wO1+RwTQ9hEtyyIMr8a+zPk2R/if1LvDuFq33w8muzfP3xfb3bHM5ecLqco1uOcFaiAuEkL7ISpXl5PF2zktBGkpozoYTUmNakZpTxEhNEaFYiDyPEKAVjvj4/Pz190UrdnQNjoii83SKMUJHnSsqu0+9d12nd6b+FVsrrwURpOk3Tt+VysVgAwDRNd+fiOKZ13batEIIxhhEmhNQfUEoJIZ5JU/b2BuezNWaaffdHuhNCpJTTA9KwJEkprWG0ACPAaAwAgNdtt6cw1LvdKKX7b3ZVVSkpzQPetg3nQrSci1nF3CJKvX61yrdbu15brT9fxgg1nAOAtVYp1TR8RoiG81a07/3Qau0NUibHo6qqcRynD5xzGGMpZd/34zhyzvO8QPPbFSEEY5IVRZikngGoGLPOWWs/mznn/9ZJqaqKCiH6fnjEzDCYPxAp8FuXsv/oAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c15d401fe7b5b4449c7319c26159d994/e4706/Using_ioBroker_with_your_WQHD_Camera_03.avif 230w", "/en/static/c15d401fe7b5b4449c7319c26159d994/d1af7/Using_ioBroker_with_your_WQHD_Camera_03.avif 460w", "/en/static/c15d401fe7b5b4449c7319c26159d994/7f308/Using_ioBroker_with_your_WQHD_Camera_03.avif 920w", "/en/static/c15d401fe7b5b4449c7319c26159d994/36c14/Using_ioBroker_with_your_WQHD_Camera_03.avif 1121w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c15d401fe7b5b4449c7319c26159d994/a0b58/Using_ioBroker_with_your_WQHD_Camera_03.webp 230w", "/en/static/c15d401fe7b5b4449c7319c26159d994/bc10c/Using_ioBroker_with_your_WQHD_Camera_03.webp 460w", "/en/static/c15d401fe7b5b4449c7319c26159d994/966d8/Using_ioBroker_with_your_WQHD_Camera_03.webp 920w", "/en/static/c15d401fe7b5b4449c7319c26159d994/eb6f0/Using_ioBroker_with_your_WQHD_Camera_03.webp 1121w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c15d401fe7b5b4449c7319c26159d994/81c8e/Using_ioBroker_with_your_WQHD_Camera_03.png 230w", "/en/static/c15d401fe7b5b4449c7319c26159d994/08a84/Using_ioBroker_with_your_WQHD_Camera_03.png 460w", "/en/static/c15d401fe7b5b4449c7319c26159d994/c0255/Using_ioBroker_with_your_WQHD_Camera_03.png 920w", "/en/static/c15d401fe7b5b4449c7319c26159d994/bb3ba/Using_ioBroker_with_your_WQHD_Camera_03.png 1121w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c15d401fe7b5b4449c7319c26159d994/c0255/Using_ioBroker_with_your_WQHD_Camera_03.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "765px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f612a2e1c5a5d98666a59f17ad301d18/bbb77/Using_ioBroker_with_your_WQHD_Camera_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACMUlEQVQoz2WSTWgTQRSAc9CARMTsliSzO7MzO7N/ySHkkEACpk2a3dIeCkkaT7ZNk6Z6kdqmQoWkjYKePAvSevAmioJgMUehmFyU4tWTIAliD/ZQb4VW3E22tX68wwy87703j/HkC4Vut7v77feLzz9e7f18ubf/+sv+drf39GNvq9Pf6vS3O73tTv9Z9zSe7H5vfz18++GT56LX+25n5/mbdqXeunXvUXmlWV1rza1s3FhuzC43yvVWZe3+Qr1Vrrdm7zTLq5tL6w/nVzfvPnh8u77ued9u/zo4WCjPXfJeoASBAAdBQEYixZBiyDCiEpQlcRBIhCAQ5K/6r1xOp9Oe4+Pjk5OTSnXRz/GMKUiSMCGEyDKljCmaphvhcDgS0XVD1w3DCGu6rmm6IAijmazn6Ojor1ypcn4/IQRj27XBNpIkOVdN0wzDwBjLsgwAGB3LnHbmOI5S6uTJ/0IIcUrIsgwhRAgFg8HzMmPMyab/4QyCEIIQSpIEAMiOj5+XCSFONmNMsWE2TlHnFRhjAEAme0bmh2O7ozqaoiiqqjLG3P4YY0EQxjLZgbxYq3Ec5yaFbVRVjUQiiqKc3cJwYaGZ0vWBXFu6OcLzqqo5JBKJlE0ymVQUxV24U5pSCiHMmdZAni8v+Hw+CCEAACEUjUZjsVg8HjcMQxRFwSYUCrnnEZ6/5n6SRnMjlUpNTU2ZpmlZ1vT0dLFYzOfzhULBNM3ckMnJyYmJCcuycrncTKn0B82t5zuicTTKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f612a2e1c5a5d98666a59f17ad301d18/e4706/Using_ioBroker_with_your_WQHD_Camera_04.avif 230w", "/en/static/f612a2e1c5a5d98666a59f17ad301d18/d1af7/Using_ioBroker_with_your_WQHD_Camera_04.avif 460w", "/en/static/f612a2e1c5a5d98666a59f17ad301d18/23eaf/Using_ioBroker_with_your_WQHD_Camera_04.avif 765w"],
              "sizes": "(max-width: 765px) 100vw, 765px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f612a2e1c5a5d98666a59f17ad301d18/a0b58/Using_ioBroker_with_your_WQHD_Camera_04.webp 230w", "/en/static/f612a2e1c5a5d98666a59f17ad301d18/bc10c/Using_ioBroker_with_your_WQHD_Camera_04.webp 460w", "/en/static/f612a2e1c5a5d98666a59f17ad301d18/33b41/Using_ioBroker_with_your_WQHD_Camera_04.webp 765w"],
              "sizes": "(max-width: 765px) 100vw, 765px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f612a2e1c5a5d98666a59f17ad301d18/81c8e/Using_ioBroker_with_your_WQHD_Camera_04.png 230w", "/en/static/f612a2e1c5a5d98666a59f17ad301d18/08a84/Using_ioBroker_with_your_WQHD_Camera_04.png 460w", "/en/static/f612a2e1c5a5d98666a59f17ad301d18/bbb77/Using_ioBroker_with_your_WQHD_Camera_04.png 765w"],
              "sizes": "(max-width: 765px) 100vw, 765px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f612a2e1c5a5d98666a59f17ad301d18/bbb77/Using_ioBroker_with_your_WQHD_Camera_04.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      